import { SelectedCoinDenom, isSelectedCoinDenom } from '@/wallet-utils'
import { Icon, TextHeading, TextLoader } from '@/components'
import { CHAIN_INFO_LIST, SUPPORTED_COIN_DENOMS } from '@/config'
// import { IBCX_CURRENCY } from '@/config/ibcx'
// import { useStIbcxApyQuery } from '@/page-components/StakeIbcx/queries'
import { useStakeStatsQuery } from '@/queries'
import { Badge, Box, Group, Input, Text, UnstyledButton } from '@mantine/core'
import BigNumber from 'bignumber.js'
import { useWallet } from '@/contexts/wallet'
import React, { useState } from 'react'
import { Command } from 'cmdk'
import { assert } from '@/utils'
import { ChainIcon } from '@/page-components/shared'
import { CoinSelectorScrollableList } from './CoinSelectorScrollableList'
import { CoinSelectorPopover } from './CoinSelectorPopover'
import { useMediumScreen } from '@/hooks'

interface CoinSelectorProps {
  onChange?: (denom: SelectedCoinDenom) => void
}

// @TODO: Now that we're no longer using this component anywhere else, we should consider
// moving this back to `Stake` or merge this with `StakeCoinSelector` in the future.
const CoinSelector = ({ onChange }: CoinSelectorProps) => {
  const { isMediumScreen } = useMediumScreen()

  const [isOpen, setIsOpen] = useState(false)

  const { selectedCoinDenom, setSelectedCoinDenom } = useWallet()

  const { data: stakeStats, isLoading: isStakeStatsLoading } = useStakeStatsQuery()

  // const { data: stIbcxApy, isLoading: isStIbcxApyLoading } = useStIbcxApyQuery()

  const handleSelectCoin = (denom: string) => {
    assert(isSelectedCoinDenom(denom), `Selected denom ${denom} is not supported`)
    setSelectedCoinDenom(denom)
    setIsOpen(false)
    onChange?.(denom)
  }

  return (
    <CoinSelectorPopover
      trigger={
        <UnstyledButton
          pl="xs"
          pr="xs"
          sx={(t) => ({
            position: 'relative',
            left: -t.spacing.xs,
            borderRadius: t.radius.sm,
            '&:hover': { background: t.colors.pink[1] }
          })}>
          <Group spacing="md">
            <TextHeading>{selectedCoinDenom}</TextHeading>

            <Box sx={{ transform: 'rotate(180deg)' }}>
              <Icon name="caretDown" size="lg" />
            </Box>
          </Group>
        </UnstyledButton>
      }
      isOpen={isOpen}
      onOpenChange={setIsOpen}>
      <Command defaultValue={selectedCoinDenom} label="Select chain to liquid stake" loop>
        <Box p="xs" sx={{ borderBottom: '1px solid #ddd' }}>
          <Command.Input asChild>
            <Input placeholder="Search by token or chain name" data-autofocus />
          </Command.Input>
        </Box>

        <CoinSelectorScrollableList>
          <Command.List>
            <Command.Empty>
              <Box p="sm">
                <Box sx={{ maxWidth: isMediumScreen ? 'auto' : '75%' }} mx="auto">
                  <Text sx={(t) => ({ color: t.colors.gray[7] })} align="center">
                    {isMediumScreen
                      ? `We couldn't find a token matching your keyword`
                      : `Sorry, we couldn't find a token matching your keyword`}
                  </Text>
                </Box>
              </Box>
            </Command.Empty>

            {SUPPORTED_COIN_DENOMS.map((denom) => {
              const chainInfo = CHAIN_INFO_LIST[denom]

              // @TODO: Uncomment out when IBCX is brought back
              // const apyRate =
              //   stakeStats == null
              //     ? '0%'
              //     : `${
              //         denom === IBCX_CURRENCY.coinDenom
              //           ? new BigNumber(stIbcxApy ?? 0).decimalPlaces(2).toNumber()
              //           : new BigNumber(stakeStats[denom].strideYield).multipliedBy(100).decimalPlaces(2).toString()
              //       }%`
              const apyRate =
                stakeStats == null
                  ? '0%'
                  : `${new BigNumber(stakeStats[denom].strideYield).multipliedBy(100).decimalPlaces(2).toString()}%`

              // @TODO: Uncomment out when IBCX is brought back
              // const isLoading = denom === IBCX_CURRENCY.coinDenom ? isStIbcxApyLoading : isStakeStatsLoading
              const isLoading = isStakeStatsLoading

              // @TODO: Add a new configuration perhaps named `CHAIN_SUPPORTS_NEW_PROMOTION` to the chain config file
              const isNew = ['ISLM'].includes(denom)

              const keywords = isNew
                ? [denom, `st${denom}`, chainInfo.chainName, chainInfo.chainId, 'new']
                : [denom, `st${denom}`, chainInfo.chainName, chainInfo.chainId]

              return (
                <Command.Item value={denom} key={denom} keywords={keywords} onSelect={handleSelectCoin} asChild>
                  <UnstyledButton
                    sx={(t) => ({
                      width: '100%',
                      padding: t.spacing.sm,
                      color: t.colors.gray[8],
                      '&:hover': { background: t.colors.gray[0] },
                      '&[aria-selected="true"]': { background: t.colors.gray[0] }
                    })}>
                    <Group spacing="xs">
                      <ChainIcon denom={denom} size={24} />

                      <Box>
                        <Text component="span" size="lg" transform="uppercase" weight={700} inline>
                          {denom}
                        </Text>

                        <Text component="span" size="lg">
                          {' · '}
                          {chainInfo.chainName}
                        </Text>

                        <Text component="span" size="lg">
                          {' · '}
                        </Text>

                        <TextLoader loading={isLoading} size="lg" component="span">
                          {apyRate}
                        </TextLoader>
                      </Box>

                      {isNew && (
                        <Badge size="xs" color="pink" sx={(t) => ({ background: t.colors.pink[1] })}>
                          New!
                        </Badge>
                      )}
                    </Group>
                  </UnstyledButton>
                </Command.Item>
              )
            })}
          </Command.List>
        </CoinSelectorScrollableList>
      </Command>
    </CoinSelectorPopover>
  )
}

export { CoinSelector }
